import React, { useState, useEffect, useCallback } from 'react';
import { useContextex } from '../context/useContext';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToProductPay } from './AddToWallet';
import AddProparty from '../addProparty/AddProparty';
import { uid } from 'uid';
import { useBookProduct } from '../hooks/useBookProduct';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const PayfastPayment = ({ product_amount, product_name, booked_for }) => {
  const {
    bookedOtherUserData,
    loginUserData,
    setTransactionId,
    transactionId,
  } = useContextex();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const snackMessage = 'Your transaction was successful';
  const navigate = useNavigate();
  const { performBookProduct } = useBookProduct();
  const { performBookPackage } = usePackagePurchase();

  // Form data for PayFast
  const formData = {
    name_first: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
    email_address:  bookedOtherUserData?.email || loginUserData?.UserLogin?.email,
    amount: product_amount,
    item_name: product_name,
  };

  // PayFast configuration
  const payFastConfig = {
    url: 'https://sandbox.payfast.co.za/eng/process',
    merchantId: '10000100',
    merchantKey: '46f0cd694581a',
    returnUrl: window.location.origin,
    cancelUrl: window.location.origin,
    notifyUrl: window.location.origin,
  };

  const postToPayFast = useCallback((url, data) => {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = url;

    Object.keys(data).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  }, []);

  // Handle form submission
  const handleSubmit = useCallback(() => {
    const paymentData = {
      merchant_id: payFastConfig.merchantId,
      merchant_key: payFastConfig.merchantKey,
      return_url: payFastConfig.returnUrl,
      cancel_url: payFastConfig.cancelUrl,
      notify_url: payFastConfig.notifyUrl,
      ...formData,
    };

    postToPayFast(payFastConfig.url, paymentData);
  }, [formData, payFastConfig, postToPayFast]);

  const handlePaymentSuccess = useCallback(() => {
    if (booked_for === 'booking') {
      setTransactionId(uid(12));
      if (transactionId) {
        performBookProduct();
      }
      setShowSnackbar(true);
    } else if (booked_for === 'package' && transactionId) {
      setTransactionId(uid(12));
      if (performBookPackage) {
        performBookPackage()
      }
    } else if (booked_for === 'wallet' && transactionId) {
      window.location.href = '/dashboard'
    }
  }, [booked_for, transactionId, setTransactionId, performBookProduct, navigate]);

  useEffect(() => {
    handleSubmit();
    handlePaymentSuccess();
  }, [handleSubmit, handlePaymentSuccess]);

  const handleCloseSnackbar = () => setShowSnackbar(false);

  return (
    <>
      {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
      {booked_for === 'package' && transactionId && <AddProparty />}
    </>
  );
};

export default PayfastPayment;
