import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchReferUserDate, footerPageData } from "../store/action";
import { getData, getReferData, getUserPageData } from "../store/selector";

export const AppContext = createContext();

const getSavedUser = () => {
    const savedUser = localStorage.getItem('loginUser');
    return savedUser ? JSON.parse(savedUser) : null;
};

export const AppProvider = ({ children }) => {

    const [isUser, setIsUser] = useState()
    const [mobileNumber, setMobileNumber] = useState('');
    const [countryCode, setCountryCode] = useState('')
    const [selectedCountryId, setSelectedCountryId] = useState('')
    const [productDetailId, setProductDetailId] = useState('')
    const [bookingId, setBookingId] = useState('')
    const [currentPage, setCurrentPage] = useState('');
    const [productFinalPrice, setProductFinalPrice] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [selectedPaymentId, setSelectedPaymentId] = useState('');
    const [otherUserGender, setOtherUserGender] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState('');

    const [showNotification, setShowNotification] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isEditSelectedProperty, setIsEditSelectedProperty] = useState(false)
    const [confirmCurrentBook, setConfirmCurrentBook] = useState(false)
    const [complateCurrentBook, setComplateCurrentBook] = useState(false)
    const [showLanguage, setShowLanguage] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [isPackdata, setIsPackData] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isExpirePackage, setIsExpirePackage] = useState(false)
    
    const [bookedProductRes, setBookedProductRes] = useState(null);
    const [loginModal, setLoginModal] = useState(null)
    const [registerModal, setRegisterModal] = useState(null)
    const [loginData, setLoginData] = useState(null)
    const [registrData, setRegistrData] = useState(null)
    const [countryListData, setCountryListData] = useState(null)
    const [authLoginToken, setAuthLoginToken] = useState(null);
    const [modalOpen, setOpenModal] = useState(null)
    const [propertyType, setPropertyType] = useState(null)
    const [userSelectedPage, setUserSelectedPage] = useState(null)
    const [editSelectedProperty, setEditSelectedProperty] = useState(null)
    const [editSelectedImage, setEditSelectedImage] = useState(null)
    const [editSelectedMyGallaryImage, setEditSelectedMyGallaryImage] = useState(null)
    const [editSelectedMyGallaryCategory, setEditSelectedMyGallaryCategory] = useState(null)
    const [userPropertyList, setUserPropertyList] = useState()
    const [userPageList, setUserPageList] = useState(null)
    const [isLikedOrNot, setIsLikedOrNot] = useState(null)
    const [referApiData, setReferApiData] = useState(null)
    const [selectedPackData, setSelectedPackData] = useState(null);
    const [walletAddAmount, setWalletAddAmount] = useState(null)
    const [userWalletAmount, setUserWalletAmount] = useState(null)
    const [paymentGatwayList, setPaymentGatwayList] = useState(null)
    const [loginUserData, setLoginUserData] = useState(null);

    const [dashboardTabData, setDashboardTabData] = useState({});
    const [memberShipData, setMemberShipData] = useState()
    const [tabsList, setTabsList] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [tabCardData, setTabCardData] = useState([]);
    const [featuredPropList, setFeaturedPropList] = useState([])
    const [bookedProductData, setBookedProductData] = useState([])
    
    const [selectedId, setSelectedId] = useState("0")
    const [selectedPackage, setSelectedPackage] = useState('1');
    const [userCurrency, setUserCurrency] = useState('$')
    const [selectedTab, setSelectedTab] = useState('Dashboard');

    const [productAmount, setProductAmount] = useState({
        bookingDays: '',
        bookingAmount: '',
        bookingTotalAmount: '',
        bookingTax: '',
        bookingWalletAmount: '0',
        bookingCouponAmount: '0',
        productTotalAmnt:'',
        productTotalTax:''
    })

    const [bookedOtherUserData, setBookedOtherUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })

    const [bookedUserData, setBookedUserData] = useState({
        bookingDate: '',
        checkIn: '',
        checkOut: '',
        noGuest: '',
        days: '',
        notes: '',
        bookedFor: '',
    })

    const [toastData, setToastData] = useState({
        message: '',
        type: ''
    })

    const imageBaseUrl = 'https://property.cscodetech.cloud/'
    // const imageBaseUrl = 'http://15.207.11.52/restate/'

    const token = localStorage.getItem('authToken')
    let isUserId = localStorage.getItem('uid')
    const dispatch = useDispatch()

    const referResData = useSelector(getReferData)
    const data = useSelector(getData)
    const userPageTabs = useSelector(getUserPageData)

    useEffect(() => {
        const handleUserReferData = async () => {
            try {
                await dispatch(fetchReferUserDate({ uid: isUserId ? isUserId : '0' }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        handleUserReferData();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (referResData) {
            setReferApiData(referResData)
        }
    }, [referResData]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchData('u_paymentgateway.php'));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch]);

    useEffect(() => {
        if (data && data?.paymentdata) {
            setPaymentGatwayList(data?.paymentdata);
        } else {
            console.error("Data or payment data is null or undefined.");
        }
    }, [data]);

    useEffect(() => {
        const fetchDataAndSetUserPageList = async () => {
            try {
                await dispatch(footerPageData());
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetUserPageList();
    }, [dispatch]);

    useEffect(() => {
        setIsAdmin(loginUserData?.type === 'admin');
    }, [loginUserData]); 

    useEffect(() => {
        if (userPageList && userPageTabs?.pagelist) {
            setUserPageList(userPageTabs.pagelist);
        }
    }, [userPageTabs, setUserPageList]);

    useEffect(() => {
        const fetchDataAndSetUserPageList = async () => {
            if (userSelectedPage) {
                try {
                    await dispatch(footerPageData());
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchDataAndSetUserPageList();
    }, [dispatch, userSelectedPage]);

    useEffect(() => {
        if (userPageTabs?.pagelist) {
            setUserPageList(userPageTabs.pagelist);
        }
    }, [userPageTabs, setUserPageList]);

    useEffect(() => {
        if (loginData) {
            setLoginUserData(loginData);
        } else {
            setLoginUserData(getSavedUser());
        }
    }, [loginData]);

    const values = {
        mobileNumber,
        countryCode,
        featuredPropList,
        productDetailId,
        showNotification,
        bookedProductData,
        bookedUserData,
        imageBaseUrl,
        bookedOtherUserData,
        isUser,
        loginUserData,
        toastData,
        productAmount,
        selectedCountryId,
        transactionId,
        bookingId,
        selectedPackage,
        propertyType,
        countryListData,
        editSelectedProperty,
        isEditSelectedProperty,
        dashboardTabData,
        userPropertyList,
        editSelectedImage,
        editSelectedMyGallaryImage,
        editSelectedMyGallaryCategory,
        complateCurrentBook,
        confirmCurrentBook,
        userCurrency,
        productFinalPrice,
        showLanguage,
        selectedTab,
        userSelectedPage,
        userPageList,
        showCancelModal,
        selectedId,
        isLikedOrNot,
        authLoginToken,
        token,
        isUserId,
        isChecked,
        loginModal,
        currentPage,
        modalOpen,
        countryData,
        paymentGatwayList,
        walletAddAmount,
        referApiData,
        selectedPaymentId,
        otherUserGender,
        tabCardData,
        tabsList,
        selectedPackData,
        selectedPaymentType,
        loginData,
        registerModal,
        registrData,
        isAdmin,
        userWalletAmount,
        isPackdata,
        isExpirePackage,
        memberShipData,
        bookedProductRes,
        setBookedProductRes,
        setMemberShipData,
        setIsExpirePackage,
        setIsPackData,
        setUserWalletAmount,
        setIsAdmin,
        setRegistrData,
        setRegisterModal,
        setLoginData,
        setSelectedPaymentType,
        setSelectedPackData,
        setLoginUserData,
        setTabsList,
        setTabCardData,
        setOtherUserGender,
        setSelectedPaymentId,
        setReferApiData,
        setWalletAddAmount,
        setPaymentGatwayList,
        setCountryData,
        setOpenModal,
        setCurrentPage,
        setLoginModal,
        setIsChecked,
        setAuthLoginToken,
        setIsLikedOrNot,
        setSelectedId,
        setShowCancelModal,
        setUserPageList,
        setUserSelectedPage,
        setSelectedTab,
        setShowLanguage,
        setProductFinalPrice,
        setUserCurrency,
        setComplateCurrentBook,
        setConfirmCurrentBook,
        setEditSelectedMyGallaryCategory,
        setEditSelectedMyGallaryImage,
        setEditSelectedImage,
        setUserPropertyList,
        setDashboardTabData,
        setIsEditSelectedProperty,
        setEditSelectedProperty,
        setCountryListData,
        setPropertyType,
        setSelectedPackage,
        setBookingId,
        setTransactionId,
        setProductAmount,
        setToastData,
        setCountryCode,
        setMobileNumber,
        setFeaturedPropList,
        setProductDetailId,
        setShowNotification,
        setBookedProductData,
        setBookedUserData,
        setBookedOtherUserData,
        setIsUser,
        setSelectedCountryId
    }

    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    )
}