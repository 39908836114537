import React, { useEffect, useState } from 'react';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { useBookProduct } from '../hooks/useBookProduct';
import AddProparty from '../addProparty/AddProparty';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const RazorpayPayment = ({ product_amount, booked_for }) => {
  const { bookedOtherUserData, loginUserData, setTransactionId, transactionId } = useContextex();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();
  const snackMessage = 'Your transaction was successful';

  // Call the hook to get its return value or to perform side effects
  const { performBookProduct } = useBookProduct();
  const { performBookPackage } = usePackagePurchase();

  useEffect(() => {
    const loadRazorpay = () => {

      const options = {
        key: 'rzp_test_oyEnEu70MpsDyP',
        amount: product_amount * 100,
        currency: 'INR',
        name: 'Go Property',
        description: 'Test Transaction',
        image: 'https://yourlogo.com/logo.png',
        handler: (response) => handlePaymentSuccess(response),
        modal:{ondismiss:() => { alert('ready')}},
        prefill: getPrefillData(),
        notes: { address: 'Corporate Office' },
        theme: { color: '#3399cc' },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    loadRazorpay();
  }, [product_amount, navigate, setTransactionId]);

  const handlePaymentSuccess = (response) => {
    // setShowSnackbar(true);
    setTransactionId(response?.razorpay_payment_id);
    setPaymentStatus(booked_for);
  };

  const getPrefillData = () => ({
    name: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
    email: bookedOtherUserData?.email || loginUserData?.UserLogin?.email,
    contact: bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile,
  });

  useEffect(() => {
    if (paymentStatus === 'booking') {
      if (performBookProduct) {
        performBookProduct();
        setShowSnackbar(true)
      }
    } else if (paymentStatus === 'package' && transactionId) {
      if (performBookPackage) {
        performBookPackage()
      }
    } else if (paymentStatus === 'wallet' && transactionId) {
      window.location.href = '/dashboard'
    }
  }, [paymentStatus, transactionId, performBookPackage, performBookProduct, navigate]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
    </>
  );
  
};
