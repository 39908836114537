import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createPortal } from 'react-dom';

export const CustomSnackbar = ({ snack_message }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    const timer = setTimeout(() => {
      setOpen(false);
    }, 6000);

    return () => clearTimeout(timer);
  }, [snack_message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return createPortal(
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose} 
      sx={{ height: '75px', background: '#def2d780' }}
    >
      <Alert 
        onClose={handleClose} 
        severity="success" 
        variant="filled" 
        sx={{ width: '100%', fontSize: '15px', padding: '20px 29px', fontFamily: 'inherit' }}
      >
        {snack_message}
      </Alert>
    </Snackbar>,
    document.body // Render the portal into the body
  );
  
};
