import React, { useEffect, useMemo, useState } from 'react'
import { fetchData, fetchPayOut, fetchReviewList, fetchSecoundData, fetchUserDashboardData, fetchUserProperty, packagePlanBuy, requestPayout } from '../store/action';
import { useContextex } from '../context/useContext';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IconArrowBackUp, IconEye, IconPencil, IconPhotoPlus, IconPlus } from '@tabler/icons-react';
import AddPropartyForm from './AddPropartyForm';
import AddImages from './AddImages';
import AddGalaryImage from './AddGalaryImage';
import AddGalleryCategory from './AddGalleryCategory';
import { ReModal } from '../partials/ReModal';
import Receipt from './Receipt';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import { getData, getLoading, getPayoutData, getReviewData, getSecoundData, getThirdData } from '../store/selector';
import { CustomSnackbar } from '../utils/CustomSnackbar';

const TabHeader = ({ tabList }) => {
    return (
        <>
            <div className="head">
                {
                    tabList?.map((item) => (

                        <div className="item">
                            <div className="text">{item}</div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

const TopLeftBar = ({ topText, bottomText }) => {
    return (
        <div className='col-sm-9 col-6 col-xs-9 col-md-8 col-lg-10 '>
            <h3>{topText}</h3>
            <div className="text-content">{bottomText}</div>
        </div>
    )
}

const Dashboard = ({ userDashboardDetails, dashCard }) => {
    const { imageBaseUrl, userCurrency, setSelectedTab, loginUserData } = useContextex();
    const { t } = useTranslation();
    return (
        <div>
            <div className='d-flex mob-dash flex-col  pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('Hello')} {loginUserData?.UserLogin?.name}</h3>
                    <div className="text-content">{t('We are glad to see you again!')}</div>
                </div>
            </div>

            <div className="grid-section-4 mb-20 ">
                {dashCard?.map((item, index) => (

                    <div className="wg-box pointer" key={index} onClick={() => setSelectedTab(item?.title)}>
                        <div className="box-icon style-1 type-row">
                            <div className="content">
                                <a className="title"><span className='text-success'>{item?.title === 'My Earning' || item?.title === 'My Payout' ? userCurrency : null}</span>{item?.report_data}</a>
                                <div className="text-content">{item?.title}</div>
                            </div>
                            <div className="icon">
                                <img src={`${imageBaseUrl}${item?.url}`} />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

const MYProperty = ({ setEditAddPropertyData, setShowAddPropertyData, handleBackButton, editAddPropertyData, showAddPropertyData }) => {
    const { t } = useTranslation();
    const { imageBaseUrl, setEditSelectedProperty, setIsEditSelectedProperty, userPropertyList, selectedTab, isAdmin } = useContextex();

    const loading = useSelector(getLoading)
    const myTabHeader = ['Listing Title', 'Type', 'Rate', 'Sqrft', 'Action']
    return (
        <>
            <div>
                <div className='d-flex mob-dash flex-col col-12 pt-5 pb-5'>
                    <TopLeftBar
                        topText={'My Property'}
                        bottomText={t('We are glad to see you again!')}
                    />

                    {showAddPropertyData || editAddPropertyData ? (

                        <div onClick={handleBackButton}>
                            <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`}>
                                <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddPropertyData(true) }}> {t('Back')} <IconArrowBackUp /></a>
                            </Link>
                        </div>

                    ) : (

                        <div>
                            <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddPropertyData(true) }}> {t('Add More')} <IconPlus /></a>
                        </div>

                    )}
                </div>
                {showAddPropertyData || editAddPropertyData ? (
                    <div>
                        <AddPropartyForm />
                    </div>
                ) : (

                    <div className='wg-box pl-44 pr-29 min_box_size'>
                        <div className="table-listing-properties mb-40">

                            <TabHeader tabList={myTabHeader} />

                            <ul>
                                {userPropertyList?.map((item, index) => (
                                    <li key={index}>
                                        <div className="my-properties-item item">
                                            <div>
                                                <div className="property">
                                                    <div className="image-container">
                                                        <img className="image" src={`${imageBaseUrl}${item.image}`} alt={item?.title} />

                                                        <div className="overlay list-tags">
                                                            {item?.is_sell == '1' ? (
                                                                <a className="tags-item for-sell bg-white text-success">{t('SOLD')}</a>
                                                            ) : (
                                                                <a className="tags-item for-sell bg-white text-primary " >{item?.buyorrent == '2' ? 'BUY' : 'SALE'}</a>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="price">${item?.price}</div>
                                                        <div className="title">
                                                            <a>{item?.title}</a>
                                                        </div>
                                                        <div className="location">
                                                            <div className="icon">
                                                                <i className="flaticon-location"></i>
                                                            </div>
                                                            <p>{item?.address}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <p>{item?.property_type}</p>
                                            </div>
                                            <div>
                                                <div className="box-status">{item?.rate}</div>
                                            </div>
                                            <div>
                                                <p>{item?.sqrft}</p>
                                            </div>
                                            <div>
                                                <ul className="wg-icon" onClick={() => {
                                                    setEditSelectedProperty(item);
                                                    setIsEditSelectedProperty(true);
                                                    setEditAddPropertyData(true);
                                                }}>
                                                    {item.is_sell !== '1' && (
                                                        <li className="edit-btns">
                                                            <i className="flaticon-edit"></i>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className='flex align-items-center justify-content-center h-100' >
                                {loading && (
                                    <div className="preload preload-container" >
                                        <div className="middle"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </>
    )
}

const MYExtraImage = ({ setShowEditExtaImage, setShowAddExtaImage, handleBackButton, showEditExtaImage, showAddExtaImage }) => {
    const { imageBaseUrl, setIsEditSelectedProperty, setEditSelectedImage, setEditSelectedMyGallaryImage, dashboardTabData, selectedTab, isAdmin } = useContextex();
    const { t } = useTranslation();
    const myTabHeader = ['Listing Title', 'Action']
    const loading = useSelector(getLoading)
    return (
        <>
            <div>

                <div className='d-flex mob-dash col-12 flex-col pt-5 pb-5'>

                    <TopLeftBar
                        topText={t('My Extra Image')}
                        bottomText={t('We are glad to see you again!')}
                    />

                    {showAddExtaImage || showEditExtaImage ? (

                        <div onClick={handleBackButton}>
                            <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`} onClick={() => { setEditSelectedMyGallaryImage(false); setShowAddExtaImage(false) }}>
                                <a className="tf-button-primary style-black active" > {t('Back')} <IconArrowBackUp /></a>
                            </Link>
                        </div>
                    ) : (
                        <div onClick={() => { setEditSelectedMyGallaryImage(false); setShowAddExtaImage(true) }}>
                            <a className="tf-button-primary style-black active"> {t('Add More')} <IconPlus /></a>
                        </div>
                    )}

                </div>


                {showAddExtaImage || showEditExtaImage ? (
                    <div className='wg-box pl-44 pr-29 min_box_size'>
                        <div className='d-flex justify-content-between'>
                            <h4>{t('Basic Information')}</h4>
                        </div>
                        <AddImages />
                    </div>
                ) : (

                    <div className="wg-box pl-44 min_box_size">
                        {showAddExtaImage ? (<AddImages />) : showEditExtaImage ? (<AddImages />) : (
                            <div className="table-listing-properties mb-40">
                                <TabHeader tabList={myTabHeader} />

                                <ul>
                                    {dashboardTabData?.extralist?.map((item, index) => (
                                        <>
                                            <div key={index} className="my-properties-item item">
                                                <div className="d-flex m-3">
                                                    <div className="d-flex w-100">
                                                        <img style={{ height: '75px', width: '75px' }} src={`${imageBaseUrl}${item?.image}`} alt={`${item?.property_title} ${item?.id}`} />

                                                        <p className="m-2">{item?.property_title}</p>
                                                        {/* <p className="m-2">hello</p> */}

                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div>
                                                    <ul className="wg-icon" onClick={() => {
                                                        setEditSelectedImage(item);
                                                        setShowEditExtaImage(true);
                                                        setIsEditSelectedProperty(true)
                                                    }}>
                                                        <li className="edit-btns">
                                                            <i className="flaticon-edit"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </ul>

                                <div className='flex align-items-center justify-content-center h-100' >
                                    {loading && (
                                        <div className="preload preload-container" >
                                            <div className="middle"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

const MYGAlImage = ({ setShowEditGalaryImages, handleBackButton, setShowAddGalaryImages, showEditGalaryImages, showAddGalaryImages }) => {
    const { imageBaseUrl, setIsEditSelectedProperty, setEditSelectedMyGallaryImage, dashboardTabData, selectedTab, isAdmin } = useContextex();
    const myTabHeader = ['Listing Title', 'Action']
    const { t } = useTranslation();
    const loading = useSelector(getLoading)
    return (
        <>
            <div>
                <div className='d-flex flex-col col-12 pt-5 pb-5'>
                    <TopLeftBar
                        topText={t('My Galary Image')}
                        bottomText={t('We are glad to see you again!')}
                    />

                    {showAddGalaryImages || showEditGalaryImages ? (
                        <div onClick={handleBackButton}>
                            <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`}>
                                <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddGalaryImages(false) }}> {t('Back')} <IconArrowBackUp /></a>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddGalaryImages(true) }}> {t('Add More')} <IconPlus /></a>
                        </div>
                    )}
                </div>
                {showAddGalaryImages || showEditGalaryImages ? (
                    <div className='wg-box pl-44 pr-29 min_box_size'>
                        <div className='d-flex justify-content-between'>
                            <h4>{t('Basic Information')}</h4>
                        </div>
                        <AddGalaryImage />
                    </div>
                ) : (
                    <div className="wg-box pl-44 min_box_size">
                        {showAddGalaryImages ? (<AddGalaryImage />) : showEditGalaryImages ? (<AddGalaryImage />) : (
                            <div className="table-listing-properties mb-40">
                                <TabHeader tabList={myTabHeader} />
                                <ul>
                                    {dashboardTabData?.gallerylist?.map((item, index) => (
                                        <>
                                            <div className="my-properties-item item" key={index}>
                                                <div className="d-flex m-3" >
                                                    <div className="d-flex w-100">
                                                        <div className='btn'>
                                                            <img src={`${imageBaseUrl}${item?.image}`} style={{ height: '75px', width: '75px' }} alt={`${item?.property_title}`} className='rounded' />
                                                        </div>
                                                        <div>
                                                            <h6 className="m-2">{item?.property_title}</h6>
                                                            <h6 className="m-2">{item?.category_title}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div onClick={() => {
                                                        setEditSelectedMyGallaryImage(item);
                                                        setIsEditSelectedProperty(true);
                                                        setShowEditGalaryImages(true)
                                                    }}>
                                                        <ul className="wg-icon">
                                                            <li className="edit-btns">
                                                                <i className="flaticon-edit"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}

                                </ul>

                                <div className='flex align-items-center justify-content-center h-100' >
                                    {loading && (
                                        <div className="preload preload-container" >
                                            <div className="middle"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

const MYGalCat = ({ setShowEditGalaryCategory, setShowAddGalaryCategory, handleBackButton, showEditGalaryCategory, showAddGalaryCategory }) => {
    const { setIsEditSelectedProperty, dashboardTabData, setEditSelectedMyGallaryCategory, selectedTab, isAdmin } = useContextex();
    const myTabHeader = ['Listing Title', 'Action']
    const { t } = useTranslation();
    const loading = useSelector(getLoading)

    return (
        <>
            <div>
                <div className='d-flex mob-dash col-12 flex-col pt-5 pb-5'>

                    <TopLeftBar
                        topText={t('My Galary Categories')}
                        bottomText={t('We are glad to see you again!')}
                    />
                    {showAddGalaryCategory || showEditGalaryCategory ? (
                        <div onClick={handleBackButton}>
                            <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`}>
                                <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddGalaryCategory(false) }}> {t('Back')} <IconArrowBackUp /></a>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <a className="tf-button-primary style-black active" onClick={() => { setIsEditSelectedProperty(false); setShowAddGalaryCategory(true) }}> {t('Add More')} <IconPlus /></a>
                        </div>
                    )}
                </div>
                {showAddGalaryCategory || showEditGalaryCategory ? (
                    <div className='wg-box pl-44 pr-29 min_box_size'>
                        <div className='d-flex justify-content-between'>
                            <h4>{t('Basic Information')}</h4>
                        </div>
                        <AddGalleryCategory />
                    </div>
                ) : (
                    <div className="wg-box pl-44">
                        {showAddGalaryCategory ? (<AddGalleryCategory />) : showEditGalaryCategory ? (<AddGalleryCategory />) : (
                            <div className="table-listing-properties mb-40">
                                <TabHeader tabList={myTabHeader} />
                                <ul>
                                    {dashboardTabData?.galcatlist?.map((item, index) => (
                                        <li key={index}>
                                            <div className="my-properties-item item">
                                                <div>
                                                    <div className="property">
                                                        <div className="image" style={{ height: '75px', width: '75px' }}>
                                                            <div className="btn border h-100 w-100 bg-primary text-white d-flex align-items-center justify-content-center">
                                                                <IconPhotoPlus />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className="price">{item?.property_title}</div>
                                                            <div className="title">
                                                                <a>{item?.cat_title}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div onClick={() => {
                                                    setIsEditSelectedProperty(true);
                                                    setEditSelectedMyGallaryCategory(item)
                                                    setShowEditGalaryCategory(true)
                                                }}>
                                                    <ul className="wg-icon">
                                                        <li className="edit-btns">
                                                            <i className="flaticon-edit"></i>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className='flex align-items-center justify-content-center h-100' >
                                    {loading && (
                                        <div className="preload preload-container" >
                                            <div className="middle"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

const MYBooking = ({ setShowReceiptTab, activeTabData, setActiveTab, activeTab, handleBackButton, showReceiptTab }) => {
    const { imageBaseUrl, setBookingId, setComplateCurrentBook, setConfirmCurrentBook, selectedTab, isAdmin, currentPage, showCancelModal } = useContextex();
    const myTabHeader = ['Listing Title', 'Total Day', 'Status', 'P_Status', 'Action'];
    const { t } = useTranslation();
    const loading = useSelector(getLoading)
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleShowReceipt = (bookId, isComplete) => {
        setBookingId(bookId);
        setConfirmCurrentBook(isComplete && currentPage === 'addproparty' ? false : true);
        setComplateCurrentBook(isComplete);
        setShowReceiptTab(true);
    };

    const renderTabContent = () => {
        if (showReceiptTab && selectedTab === 'My Booking') {
            return <Receipt />;
        }

        return (
            <div className="table-listing-properties mb-40">
                <div className="widget-tabs style-2">
                    <ul className="widget-menu-tab">
                        <li
                            className={`item-title ${activeTab === 'active' ? 'active' : ''}`}
                            onClick={() => handleTabClick('active')}
                        >
                            <span className="inner">{t('Current Booking')}</span>
                        </li>
                        <li
                            className={`item-title ${activeTab == 'completed' ? 'active' : ''}`}
                            onClick={() => handleTabClick('completed')}
                        >
                            <span className="inner">{t('Completed')}</span>
                        </li>
                    </ul>
                </div>

                <TabHeader tabList={myTabHeader} />

                <ul>
                    {activeTabData?.length > 0 ? (
                        activeTabData?.map((item, index) => (
                            <li key={index}>
                                <div className="my-properties-item item">
                                    <div className="property">
                                        <div className="image booking-image h-100 w-100">
                                            <img src={`${imageBaseUrl}${item?.prop_img}`} alt={item?.prop_title} />
                                        </div>
                                        <div>
                                            <div className="price">${item?.prop_price}</div>
                                            <div className="title">
                                                <a>{item?.prop_title}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p>{item?.total_day} {t('Days')}</p>
                                    </div>
                                    <div>
                                        <div className="box-status">{item?.book_status}</div>
                                    </div>
                                    <div>
                                        <p>{item?.p_method_id === '2' ? 'Unpaid' : 'Paid'}</p>
                                    </div>
                                    <div>
                                        <ul className="wg-icon" onClick={() => handleShowReceipt(item?.book_id, activeTab == 'completed')}>
                                            <li className="p-3 edit-btns">
                                                <IconEye />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <div className='align-items-center mt-5 justify-content-center d-flex'>
                            <div>
                                <p className='d-flex justify-content-center'>{t('Oops')}...!</p>
                                <h4>{t('No Any Booking Available')}..</h4>
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        );
    };

    return (
        <>
            <div className='d-flex flex-col col-12 mob-dash pt-5 pb-5'>
                <TopLeftBar topText={'My Booking'} bottomText={'We are glad to see you again!'} />

                {showReceiptTab && selectedTab === 'My Booking' && (
                    <div onClick={handleBackButton}>
                        <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`}>
                            <a className="tf-button-primary style-black active" onClick={() => setShowReceiptTab(false)}>
                                Back <IconArrowBackUp />
                            </a>
                        </Link>
                    </div>
                )}
            </div>
            <div className="wg-box pl-44 pr-29 min_box_size">
                {renderTabContent()}
            </div>
        </>
    );
};

const MYEnquiry = () => {
    const { imageBaseUrl, dashboardTabData } = useContextex();
    const myTabHeader = ['Listing Title', 'Name', 'Mobile Number:']
    const { t } = useTranslation();
    const loading = useSelector(getLoading)
    return (
        <>
            <div className='d-flex mob-dash flex-col col-12 pt-5 pb-5'>
                <TopLeftBar
                    topText={t('My Enquiry')}
                    bottomText={t('Your Enquiry Data')}
                />

            </div>
            <div className="wg-box pl-44 pr-29 min_box_size">
                <div className="table-listing-properties mb-40">
                    <TabHeader tabList={myTabHeader} />
                    <ul>
                        {dashboardTabData?.EnquiryData?.map((item, index) => (
                            <>
                                <div className="my-properties-item item" key={index}>
                                    <div key={index} className="d-flex m-3">
                                        <div className="d-flex w-100">
                                            <img className='rounded ' style={{ height: '75px', width: '75px' }} src={`${imageBaseUrl}${item?.image}`} alt={item?.title} />
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <h6 className="m-2">{item?.title}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 blog__popular__single-content ">
                                        <p>{item?.name}</p>
                                    </div>
                                    <div className=" col-6 blog__popular__single-content ">
                                        <p>{item?.mobile}</p>
                                    </div>
                                </div>
                            </>
                        ))}

                    </ul>

                    <div className='flex align-items-center justify-content-center h-100' >
                        {loading && (
                            <div className="preload preload-container" >
                                <div className="middle"></div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}

const MYEarning = ({
    myEarningData,
    userCurrency,
    userDashboardDetails,
    setShowReceiptTab,
    showReceiptTab,
    handleBackButton,
}) => {
    const myTabHeader = ['Listing Title', 'Total Day', 'Status', 'P_Status', 'Action'];
    const { t } = useTranslation();
    const { imageBaseUrl, setBookingId, selectedTab, isAdmin } = useContextex();
    const loading = useSelector(getLoading)

    const handleShowReceipt = (bookId) => {
        setBookingId(bookId);
        setShowReceiptTab(true);
    };

    const renderTabContent = () => {
        if (showReceiptTab && selectedTab === 'My Earning') {
            return <Receipt />;
        }

        return (
            <>
                <div >
                    <div className="table-listing-properties mb-40">
                        <TabHeader tabList={myTabHeader} />
                        <ul>
                            {myEarningData?.length > 0 ? (
                                myEarningData?.map((item, index) => (
                                    <li key={index}>
                                        <div className="my-properties-item item">
                                            <div className="property">
                                                <div className="image booking-image h-100 w-100">
                                                    <img
                                                        src={`${imageBaseUrl}${item?.prop_img}`}
                                                        alt={item?.prop_title}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="price">${item?.prop_price}</div>
                                                    <div className="title">
                                                        <a>{item?.prop_title}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p>{item?.total_day} {t('Days')}</p>
                                            </div>
                                            <div>
                                                <div className="box-status">{item?.book_status}</div>
                                            </div>
                                            <div>
                                                <p>{item?.p_method_id === '2' ? 'Unpaid' : 'Paid'}</p>
                                            </div>
                                            <div>
                                                <ul className="wg-icon" onClick={() => handleShowReceipt(item?.book_id)}>
                                                    <li className="p-3 edit-btns">
                                                        <IconEye />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <div className="align-items-center mt-5 justify-content-center d-flex">
                                    <div>
                                        <p className="d-flex justify-content-center">{t('Oops')}...!</p>
                                        <h4>{t('No Any Booking Available')}..</h4>
                                    </div>
                                </div>
                            )}
                        </ul>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className="d-flex flex-col col-12 mob-dash pt-5 pb-5">
                
                <TopLeftBar
                    topText={t('My Earning')}
                    bottomText={t('We are glad to see you again!')}
                />

                {showReceiptTab && selectedTab === 'My Earning' ? (
                    <div onClick={handleBackButton}>

                        <Link to={isAdmin ? `/${selectedTab}/add` : `/addProparty/${selectedTab}/add`}>
                            <a className="tf-button-primary style-black active" onClick={() => setShowReceiptTab(false)}>
                                Back <IconArrowBackUp />
                            </a>
                        </Link>

                    </div>
                ) : (
                    
                    <div>
                        <h4 className="style-black active">
                            {t('Total Earning')}{' '}
                            <h2 className="text-success">
                                {userCurrency}
                                {userDashboardDetails[5].report_data}
                            </h2>
                        </h4>
                    </div>

                )}
                
            </div>
            <div className="wg-box pl-44 pr-29 min_box_size">
                {renderTabContent()}
            </div>
        </>
    );
};

const MYPayout = ({ showTransactionModal, setShowTransactionModal, activeForm, setIsOpenModal, isOpenModal, setSelectedTransactionData, userDashboardDetails }) => {
    const { userCurrency, isAdmin } = useContextex();
    const myTabHeader = ['Order ID', 'Amount', 'Req_Date', 'Req_Type', 'Status', 'Action']
    const { t } = useTranslation();

    const handleViewReq = (item) => {
        setSelectedTransactionData(item);
        setShowTransactionModal(!showTransactionModal)
    }

    return (
        <>
            <div className='d-flex mob-dash flex-col pt-5 col-12 pb-5'>
                <div className='col-sm-9 col-6 col-xs-9 col-md-8 col-lg-10 '>
                    <h3>{t('My Payout')}</h3>
                    <div className="text-content">{t('Your Total Earning is')} <span style={{ color: "red" }}>{userCurrency}
                        {userDashboardDetails[5].report_data}</span>
                    </div>
                </div>

                <div >
                    <a className="tf-button-primary style-black active" onClick={() => setIsOpenModal(!isOpenModal)} >{t('Request')}<IconPlus /></a>
                </div>
            </div>

            <div className="wg-box pl-44 pr-29 min_box_size">
                <div className="table-listing-properties mb-40">
                    <div className="mt-5 pl-44 pr-29">
                        <div className="table-text-infor default mb-40">
                            <TabHeader tabList={myTabHeader} />
                            <ul>
                                {activeForm?.Payoutlist?.map((item, index) => (
                                    <>
                                        <li key={index}>
                                            <div className="text-infor-item item">

                                                <div>
                                                    <div className="title">{item?.payout_id}</div>
                                                </div>

                                                <div>
                                                    <div className="title">{userCurrency}{item?.amt}</div>
                                                </div>

                                                <div>
                                                    <p>{item?.r_date}</p>
                                                </div>

                                                <div>
                                                    <p>{item?.r_type}</p>
                                                </div>

                                                <div>
                                                    <p><span className="process"></span>{item.status}</p>
                                                </div>

                                                <div>
                                                    <ul className="wg-icon" onClick={() => handleViewReq(item)}>
                                                        <li className="p-3 edit-btns">
                                                            <IconEye />
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>
                                    </>
                                ))}

                                {activeForm?.Payoutlist?.length == 0 && (
                                    <div className='align-items-center justify-content-center mt-5 d-flex'>
                                        <div>
                                            <p className='d-flex  justify-content-center'>{t('Opps')}...!</p>
                                            <h4 >{t('No Any Payout Data Available')}..</h4>
                                        </div>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

function AddProparty() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('active');
    const [userDashboardDetails, setUserDashboardDetails] = useState([]);
    const [activeTabData, setActiveTabData] = useState({})

    const [activeForm, setActiveForm] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(null);
    const [reviewListData, setReviewListData] = useState(null)
    const [myEarningData, setMyEarningData] = useState(null)
    const [selectedId, setSelectedId] = useState(null)
    const [sidebarData, setSidebarData] = useState(null)
    const [dashCard, setDashCard] = useState(null)
    const [selectedTransactionData, setSelectedTransactionData] = useState(null)

    const [showTransactionModal, setShowTransactionModal] = useState(false)
    const [showReceiptTab, setShowReceiptTab] = useState(false)
    const [showAddPropertyData, setShowAddPropertyData] = useState(false)
    const [editAddPropertyData, setEditAddPropertyData] = useState(false)
    const [showAddExtaImage, setShowAddExtaImage] = useState(false)
    const [showEditExtaImage, setShowEditExtaImage] = useState(false)
    const [showAddGalaryCategory, setShowAddGalaryCategory] = useState(false)
    const [showEditGalaryCategory, setShowEditGalaryCategory] = useState(false)
    const [showAddGalaryImages, setShowAddGalaryImages] = useState(false)
    const [showEditGalaryImages, setShowEditGalaryImages] = useState(false)
    const [isCanvasActive, setIsCanvasActive] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [refetchPayout,setRefetchPayout] = useState(false)

    const [minWithdraw, setMinWithdraw] = useState()

    const [selectPayoutType, setSelectPayoutType] = useState('')
    const [payoutAmount, setPayoutAmount] = useState('')
    const [payoutUpi, setPayoutUpi] = useState('')
    const [payoutEmail, setPayoutEmail] = useState('')
    const [snackMsg, setSnackMsg] = useState('')

    const [payoutBankDetails, setPayoutBankDetails] = useState({
        ac_no: '',
        bankName: '',
        ac_holderName: '',
        IFSC_code: ''
    });

    const data = useSelector(getData)
    const secoundData = useSelector(getSecoundData)
    const thirdData = useSelector(getThirdData)
    const reviewData = useSelector(getReviewData)
    const payoutData = useSelector(getPayoutData);

    const { isUserId, imageBaseUrl, setCurrentPage, currentPage, isAdmin, setDashboardTabData, setUserPropertyList, setComplateCurrentBook, userCurrency, setSelectedTab, selectedTab,  setMemberShipData } = useContextex();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { title } = useParams()

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (isUserId && selectedPackData?.id && selectedPaymentType && transactionId) {
    //             try {
    //                 await dispatch(packagePlanBuy({
    //                     uid: isUserId,
    //                     transaction_id: transactionId,
    //                     plan_id: selectedPackData.id,
    //                     pname: selectedPaymentType
    //                 }));
    //             } catch (error) {
    //                 console.error("Error fetching package purchase data:", error);
    //             }
    //         }
    //     };

    //     fetchData();
    // }, [transactionId]);

    useEffect(() => {
        var dashboard = function () {
            if ($('body').hasClass('dashboard')) {
                $('.btn-canvas').on('click', function () {
                    $(this).toggleClass('active');
                    $(this).closest('#page').find('.section-content-right').toggleClass('full');
                    $(this).closest('#page').find('.section-menu-left').toggleClass('null');
                });
            }
        }
        return dashboard()
    }, [])

    useEffect(() => {
        setCurrentPage('addproparty')
    }, [currentPage])

    const navTabs = userDashboardDetails.find(navTabs => navTabs.title === title);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchUserDashboardData({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (data && (data.report_data || data.withdraw_limit || data.member_data)) {
            setUserDashboardDetails(data.report_data || []);
            setMinWithdraw(data.withdraw_limit || null);
            setMemberShipData(data.is_subscribe || null);
        }
    }, [data]);

    useEffect(() => {
        const baseSidebarData = [{ title: 'Dashboard' }, ...userDashboardDetails];

        if (isAdmin) {
            const newBaseSidebarData = [baseSidebarData.pop()]
            setSidebarData(baseSidebarData);
        } else {
            setSidebarData(baseSidebarData);
        }
    }, [userDashboardDetails, isAdmin]);

    useEffect(() => {
        const baseSidebarData = userDashboardDetails;

        if (selectedTab == 'Dashboard' && isAdmin) {
            const newBaseSidebarData = [baseSidebarData.pop()]
            setDashCard(baseSidebarData);
        } else {
            setDashCard(baseSidebarData);
        }
    }, [userDashboardDetails, isAdmin]);

    useEffect(() => {
        const fetchUserProperties = async () => {
            try {
                await dispatch(fetchUserProperty({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching user properties:", error);
            }
        };

        fetchUserProperties();

    }, [dispatch, showAddPropertyData, editAddPropertyData]);

    useEffect(() => {
        if (secoundData?.proplist) {
            setUserPropertyList(secoundData.proplist);
        }
    }, [secoundData]);

    useEffect(() => {
        const fetchActiveTabData = async () => {
            try {
                if (selectedTab === 'My Earning') {
                    await dispatch(fetchSecoundData('u_my_book.php', { uid: isUserId, status: 'completed' }));
                } else {
                    await dispatch(fetchSecoundData('u_my_book.php', { uid: isUserId, status: activeTab }));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchActiveTabData();
    }, [dispatch, isUserId, activeTab, selectedTab, showReceiptTab]);

    useEffect(() => {
        if (secoundData && secoundData.statuswise) {
            if (selectedTab === 'My Earning') {
                setMyEarningData(secoundData.statuswise);
            } else {
                setActiveTabData(secoundData.statuswise);
            }
        }
    }, [secoundData, selectedTab]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                let endpoint = '';
                switch (selectedTab) {
                    case 'My Extra Images':
                        endpoint = 'u_extra_list.php';
                        break;
                    case 'My Gallery Category':
                        endpoint = 'u_gallery_cat_list.php';
                        break;
                    case 'My Gallery Images':
                        endpoint = 'gallery_list.php';
                        break;
                    case 'My Enquiry':
                        endpoint = 'u_my_enquiry.php';
                        break;
                    default:
                        break;
                }
                await dispatch(fetchData(endpoint, { uid: isUserId }));

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, selectedTab, isUserId, showEditExtaImage, showAddExtaImage, showAddGalaryCategory, showEditGalaryCategory, showEditGalaryImages, showAddGalaryImages]);

    useEffect(() => {
        if (data) {
            setDashboardTabData(data);
        }
    }, [data]);

    useEffect(() => {
        if (selectedTab == 'My Payout') {

            const fetchDataAndSetCategories = async () => {
                try {
                    await dispatch(fetchPayOut({ owner_id: isUserId }));
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchDataAndSetCategories();
        }
    }, [selectedTab, dispatch,refetchPayout]);

    useEffect(() => {
        if (thirdData) {
            setActiveForm(thirdData);
        }
    }, [thirdData]);

    useEffect(() => {
        if (selectedTab == 'Total Review') {

            const fetchDataAndSetCategories = async () => {
                try {
                    await dispatch(fetchReviewList({ orag_id: isUserId }));
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchDataAndSetCategories();
        }
    }, [selectedTab, dispatch, isUserId]);

    useEffect(() => {
        if (reviewData && reviewData?.reviewlist) {
            setReviewListData(reviewData?.reviewlist);
        }
    }, [reviewData]);

    useEffect(() => {
        var tabs = function () {
            $('.widget-tabs').each(function () {
                $(this).find('.widget-content-tab').children().hide();
                $(this).find('.widget-content-tab').children(".active").show();
                $(this).find('.widget-menu-tab').children('.item-title').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive);
                    contentActive.addClass('active').fadeIn("slow");
                    contentActive.siblings().removeClass('active');
                    $(this).addClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive).siblings().hide();
                });
            });
            $('.widget-tabs-1').each(function () {
                $(this).find('.widget-content-tab-1').children().hide();
                $(this).find('.widget-content-tab-1').children(".active-1").show();
                $(this).find('.widget-menu-tab-1').children('.item-title-1').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive);
                    contentActive.addClass('active-1').fadeIn("slow");
                    contentActive.siblings().removeClass('active-1');
                    $(this).addClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive).siblings().hide();
                });
            });
        };

        return tabs()
    }, [])

    const resetForm = () => {
        setPayoutAmount('');
        setSelectPayoutType('');
        setPayoutBankDetails({
            ac_no: '',
            bankName: '',
            ac_holderName: '',
            IFSC_code: ''
        });
        setPayoutUpi('');
        setPayoutEmail('');
    };

    const handlePayoutRequest = async (event) => {
        event.preventDefault();

        const { ac_no, bankName, ac_holderName, IFSC_code } = payoutBankDetails;

        const responseData = {
            owner_id: isUserId,
            amt: payoutAmount,
            r_type: selectPayoutType,
            acc_number: ac_no || '',
            bank_name: bankName || '',
            acc_name: ac_holderName || '',
            ifsc_code: IFSC_code || '',
            upi_id: selectPayoutType === 'UPI' ? payoutUpi || '' : '',
            paypal_id: selectPayoutType === 'Paypal' ? payoutEmail || '' : ''
        };

        try {
            await dispatch(requestPayout(responseData));
            setShowSnackbar(true);
            resetForm();
            setRefetchPayout(!refetchPayout)
            setIsOpenModal(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (payoutData) {
            setSnackMsg(payoutData?.ResponseMsg)
            setRefetchPayout(false)
        }
    }, [payoutData])

    const handleBackButton = () => {
        navigate(-1, { fallback: '/fallback' });
        setShowEditGalaryImages(false);
        setShowAddGalaryImages(false);
        setShowEditGalaryCategory(false);
        setShowEditExtaImage(false);
        setShowAddExtaImage(false);
        setEditAddPropertyData(false);
        setShowAddPropertyData(false);
        setActiveTab('active');
    };

    const closeModal = () => setIsOpenModal(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPayoutBankDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectPayout = (event) => {
        setSelectPayoutType(event.target.getAttribute('data-value'))
    }

    const handleCanvasClick = () => {
        setIsCanvasActive(!isCanvasActive);
    };

    const handleCloseSnackbar = () => setShowSnackbar(false);

    return (
        <>
            <div id="wrapper">
                <div id="page" className="layout-wrap background-F9F9F9">
                    <div className="main-content spacing-20">

                        <div className="layout-wrap-inner">

                            <div className={`section-menu-left ${isCanvasActive ? 'null' : ''}`}>
                                <div className="menu-content">
                                    <ul>
                                        {sidebarData?.map((item, index) => {
                                            // Format the title to be used in the URL
                                            const formattedText = item.title.replace(/\s+/g, '_');
                                            const FinalText = formattedText.toLowerCase()

                                            return (

                                                <li key={index} className={`pointer ${selectedTab === item.title ? 'active' : ''}`} onClick={() => {
                                                    setSelectedTab(item.title);
                                                    setSelectedId(index + 1);
                                                }}>
                                                    <Link to={isAdmin ? `/${FinalText}` : `/addProparty/${FinalText}`} className="w-100">
                                                        <span className="py-0 px-0 w-100">
                                                            {item.title.substring(0, 20)}
                                                        </span>
                                                    </Link>
                                                </li>

                                            );

                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className={`section-content-right ${isCanvasActive ? 'full' : ''}`}>

                                {selectedTab === 'Dashboard' && (
                                    <Dashboard
                                        userDashboardDetails={userDashboardDetails}
                                        dashCard={dashCard}
                                    />
                                )}

                                {selectedTab === 'My Property' && (
                                    <MYProperty
                                        setEditAddPropertyData={setEditAddPropertyData}
                                        setShowAddPropertyData={setShowAddPropertyData}
                                        handleBackButton={handleBackButton}
                                        editAddPropertyData={editAddPropertyData}
                                        showAddPropertyData={showAddPropertyData}
                                    />
                                )}

                                {selectedTab === 'My Extra Images' && (
                                    <MYExtraImage
                                        setShowEditExtaImage={setShowEditExtaImage}
                                        setShowAddExtaImage={setShowAddExtaImage}
                                        handleBackButton={handleBackButton}
                                        showEditExtaImage={showEditExtaImage}
                                        showAddExtaImage={showAddExtaImage}
                                    />
                                )}

                                {selectedTab === 'My Gallery Category' && (
                                    <MYGalCat
                                        setShowEditGalaryCategory={setShowEditGalaryCategory}
                                        setShowAddGalaryCategory={setShowAddGalaryCategory}
                                        handleBackButton={handleBackButton}
                                        showEditGalaryCategory={showEditGalaryCategory}
                                        showAddGalaryCategory={showAddGalaryCategory}
                                    />
                                )}

                                {selectedTab === 'My Gallery Images' && (
                                    <MYGAlImage
                                        setShowEditGalaryImages={setShowEditGalaryImages}
                                        handleBackButton={handleBackButton}
                                        setShowAddGalaryImages={setShowAddGalaryImages}
                                        showEditGalaryImages={showEditGalaryImages}
                                        showAddGalaryImages={showAddGalaryImages}
                                    />
                                )}

                                {selectedTab === 'My Booking' && (
                                    <MYBooking
                                        setShowReceiptTab={setShowReceiptTab}
                                        activeTabData={activeTabData}
                                        setActiveTab={setActiveTab}
                                        activeTab={activeTab}
                                        handleBackButton={handleBackButton}
                                        showReceiptTab={showReceiptTab}
                                        setComplateCurrentBook={setComplateCurrentBook}
                                    />
                                )}

                                {selectedTab === 'My Enquiry' && (
                                    <MYEnquiry />
                                )}

                                {selectedTab === 'My Earning' && (
                                    <MYEarning
                                        imageBaseUrl={imageBaseUrl}
                                        myEarningData={myEarningData}
                                        userCurrency={userCurrency}
                                        userDashboardDetails={userDashboardDetails}
                                        setShowReceiptTab={setShowReceiptTab}
                                        showReceiptTab={showReceiptTab}
                                        handleBackButton={handleBackButton}
                                    />
                                )}

                                {selectedTab === 'My Payout' && (
                                    <MYPayout
                                        setShowTransactionModal={setShowTransactionModal}
                                        activeForm={activeForm}
                                        setIsOpenModal={setIsOpenModal}
                                        isOpenModal={isOpenModal}
                                        setSelectedTransactionData={setSelectedTransactionData}
                                        userDashboardDetails={userDashboardDetails}
                                        showTransactionModal={showTransactionModal}
                                    />
                                )}

                                {selectedTab === 'Total Review' && (
                                    <>
                                    
                                        <div className='d-flex mob-dash col-12 flex-col pt-5 pb-5'>
                                            <TopLeftBar
                                                topText={'Total Review'}
                                                bottomText={'We are glad to see you again!'}
                                            />
                                        </div>

                                        <div className="wg-box pl-44 pr-29 min_box_size">
                                            <div className="reviews-wrap mt-0 mb-40">
                                                <ul>
                                                    {reviewListData?.map((item, index) => (
                                                        <li className="d-flex flex-column" key={index}>
                                                            <div className="ratings">
                                                                {[...Array(5)].map((_, i) => (
                                                                    <i key={i} className="flaticon-star-1"></i>
                                                                ))}
                                                                <p>({item?.total_rate} Out Of 5)</p>
                                                            </div>
                                                            <div className="content">
                                                                <p>{item?.rate_text}</p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </>
                                )}

                            </div>

                            <div className={`btn-canvas ${isCanvasActive ? 'active' : ''}`} onClick={handleCanvasClick}>
                                <span></span>
                                <div className='text-content'>{t('AddProparty Navigation')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {isOpenModal && (
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/def/add-property.jpg" alt="" />
                        <h3>{t('Withdraw Your Earnings')}</h3>
                    </div>

                    <div className="content-right p-5">
                        <form className="form-basic-information flex flex-column">
                            <h4 className="d-flex justify-content-center">{t('Payout Request')}</h4>
                            
                            <h6 className="text-black d-flex justify-content-center">
                                {t('Minimum amount')}: <span className="text-success">${minWithdraw}</span>
                            </h6>

                            <fieldset className="name">
                                <input
                                    type="text"
                                    className="w-100"
                                    value={payoutAmount}
                                    onChange={(e) => setPayoutAmount(e.target.value)}
                                    placeholder="Amount"
                                    required
                                />
                            </fieldset>

                            <div className="nice-select" tabIndex="0">
                                <span className="current">{t('Select Type')}</span>
                                <ul className="list">
                                    {['UPI', 'Bank Transfer', 'Paypal'].map((type) => (
                                        <li
                                            key={type}
                                            data-value={type}
                                            className={`option ${selectPayoutType === type ? 'selected' : ''}`}
                                            onClick={handleSelectPayout}
                                        >
                                            {t(type)}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {selectPayoutType === 'UPI' && (
                                <fieldset className="name">
                                    <input
                                        type="text"
                                        className="w-100"
                                        value={payoutUpi}
                                        onChange={(e) => setPayoutUpi(e.target.value)}
                                        placeholder="UPI"
                                        required
                                    />
                                </fieldset>
                            )}

                            {selectPayoutType === 'Bank Transfer' && (
                                <>
                                    {['ac_no', 'bankName', 'ac_holderName', 'IFSC_code'].map((field, index) => (
                                        <fieldset className="name" key={index}>
                                            <input
                                                type="text"
                                                className="w-100"
                                                value={payoutBankDetails[field]}
                                                onChange={handleInputChange}
                                                name={field}
                                                placeholder={field.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                                                required
                                            />
                                        </fieldset>
                                    ))}
                                </>
                            )}

                            {selectPayoutType === 'Paypal' && (
                                <fieldset className="name">
                                    <input
                                        type="text"
                                        className="w-100"
                                        value={payoutEmail}
                                        onChange={(e) => setPayoutEmail(e.target.value)}
                                        placeholder="Email ID"
                                        required
                                    />
                                </fieldset>
                            )}

                            <div className="col-12 mt-5 d-flex justify-content-between">
                                <a
                                    className="tf-button-primary style-black active mx-2"
                                    onClick={() => setIsOpenModal(false)}
                                >
                                    {t('Cancel')}
                                </a>
                                <a
                                    className="tf-button-primary active"
                                    onClick={handlePayoutRequest}
                                    disabled={minWithdraw >= parseFloat(payoutAmount)}
                                >
                                    {t('Proceed')} <i className="icon-arrow-right-add"></i>
                                </a>
                            </div>
                            
                        </form>
                    </div>
                    
                </ReModal>
            )}

            {showTransactionModal &&
                <ReModal isOpenModal={showTransactionModal} onClose={() => setShowTransactionModal(false)}>
                    <div className="image-left">
                        <img src="./../assets/images/def/pay-out.jpg" alt="" />
                        <h3>{t('Payout Information')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex  flex-column'>
                            <h4 className='d-flex justify-content-center'>{t('Payout Request')}</h4>

                            <div className='d-flex px-3 col-12'>
                                <p className='pt-1 col-6'>{t('Status')} : </p>
                                <p className='col-6'>{selectedTransactionData?.status}</p>
                            </div>

                            <div className='d-flex px-3 col-12'>
                                <p className=' pt-1 col-6'>{t('Transaction Date')} : </p>
                                <p className='col-6'>{selectedTransactionData?.r_date}</p>
                            </div>

                            {selectedTransactionData?.r_type === 'UPI' && (
                                <>
                                    <div className='d-flex px-3 col-12'>
                                        <p className=' pt-1 col-6'>{t('Payout Upi Id')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.upi_id}</p>
                                    </div>
                                </>
                            )}

                            {selectedTransactionData?.r_type === 'BANK Transfer' && (
                                <>

                                    <div className='d-flex px-3 col-12'>
                                        <p className='pt-1 col-6'>{t('Bank Name')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.bank_name}</p>
                                    </div>

                                    <div className='d-flex px-3 col-12'>
                                        <p className='pt-1 col-6'>{t('Bank Account No')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.acc_number}</p>
                                    </div>

                                    <div className='d-flex px-3 col-12'>
                                        <p className='pt-1 col-6'>{t('IFSC Code')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.ifsc_code}</p>
                                    </div>

                                    <div className='d-flex px-3 col-12'>
                                        <p className='pt-1 col-6'> {t('Account Holder Name')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.acc_name}</p>
                                    </div>

                                </>
                            )}

                            {selectedTransactionData?.r_type === 'Paypal' && (
                                <>
                                    <div className='d-flex px-3 col-12'>
                                        <p className='pt-1 col-6'>{t('Paypal Email Id')} : </p>
                                        <p className='col-6'>{selectedTransactionData?.paypal_id}</p>
                                    </div>
                                </>
                            )}

                        </form>
                    </div>
                </ReModal>
            }

            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={payoutData?.ResponseMsg} />}

        </>
    )
}

export default AddProparty


