import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContextex, usePayment } from "../context/useContext";
import { fetchBookedProductData } from "../store/action";
import { useNavigate } from "react-router-dom";
import { getBookData } from "../store/selector";

export const useBookProduct = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bookedProduct = useSelector(getBookData);

    const {
        isUserId,
        bookedUserData,
        productAmount,
        bookedProductData,
        transactionId,
        selectedPaymentId,
        productFinalPrice,
        bookedOtherUserData,
        otherUserGender,
        setTransactionId,
        setSelectedPaymentId
    } = useContextex();

    const {buyProperty} = usePayment()

    const createBookingData = useCallback(() => ({
        prop_id: bookedProductData?.propetydetails?.id,
        uid: isUserId,
        check_in: bookedUserData?.checkIn,
        check_out: bookedUserData?.checkOut,
        subtotal: productAmount?.productTotalAmnt,
        total: productFinalPrice,
        total_day: productAmount?.bookingDays,
        cou_amt: productAmount?.bookingCouponAmount,
        wall_amt: productAmount?.bookingWalletAmount,
        transaction_id: ['2', '5'].includes(selectedPaymentId) ? '0' : transactionId,
        add_note: bookedUserData?.notes,
        prop_price: bookedProductData?.propetydetails?.price,
        book_for: bookedUserData?.bookedFor,
        p_method_id: selectedPaymentId,
        tax: productAmount?.productTotalTax,
        noguest: bookedUserData?.noGuest,
        ...(bookedUserData?.bookedFor === 'other' && {
            fname: bookedOtherUserData?.firstName,
            lname: bookedOtherUserData?.lastName,
            gender: otherUserGender,
            email: bookedOtherUserData?.email,
            mobile: bookedOtherUserData?.phone,
            ccode: '+91',
            country: 'india'
        })
    }), [
        bookedProductData,
        bookedUserData,
        productAmount,
        productFinalPrice,
        selectedPaymentId,
        transactionId,
        isUserId,
        bookedOtherUserData,
        otherUserGender
    ]);

    const bookProduct = useCallback(async () => {
        if (transactionId && !['2', '5'].includes(selectedPaymentId)) {
            try {
                await dispatch(fetchBookedProductData(createBookingData()));
            } catch (error) {
                console.error("Error booking product:", error);
            }
        }
    }, [dispatch, createBookingData, transactionId, selectedPaymentId]);

    useEffect(() => {
        if (transactionId) {
            bookProduct();
            buyProperty()
            setTransactionId('');
            setSelectedPaymentId('');
        }
    }, [transactionId, bookProduct, navigate, setTransactionId, setSelectedPaymentId]);

    useEffect(() => {
        if (bookedProduct) {
            console.log('Booked Product:', bookedProduct);
        }
    }, [bookedProduct]);
    
    return {
        bookedProduct,
        bookProduct,
    };
};
