import React, { useEffect, useState } from 'react';
import { PaystackButton } from 'react-paystack';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToProductPay } from './AddToWallet';
import { useNavigate } from 'react-router-dom';
import { useBookProduct } from '../hooks/useBookProduct';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const PaystackPayment = ({ product_amount, booked_for }) => {
    const { bookedOtherUserData, loginUserData, setTransactionId, transactionId } = useContextex();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const snack_message = 'Your transaction was successful';

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const publicKey = 'pk_test_71d15313379591407f0bf9786e695c2616eece54';

    const email = bookedOtherUserData?.email || loginUserData?.UserLogin?.email;
    const amount = product_amount * 100;
    const first_name = bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name;
    const last_name = bookedOtherUserData?.lastname || loginUserData?.UserLogin?.name;
    const phone = bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile;
    const navigate = useNavigate()
    const { performBookProduct } = useBookProduct();
    const { performBookPackage } = usePackagePurchase();

    const paystackProps = {
        email,
        amount,
        first_name,
        last_name,
        phone,
        publicKey,
        text: 'Pay Now',
        onSuccess: (response) => {
            setTransactionId(response?.transaction);

            if (booked_for == 'booking') {
                if (transactionId) {
                    performBookProduct();
                }
            }
            else if (booked_for == 'package' && transactionId) {
                if (transactionId) {
                    performBookPackage();
                }
            } else if (booked_for === 'wallet' && transactionId) {
                window.location.href = '/dashboard'
            }
            setShowSnackbar(true);
        },
        onClose: () => {
            console.log('Payment closed');
        }
    };

    return (
        <div>
            <PaystackButton {...paystackProps} />
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
            {booked_for === 'package' && transactionId && <AddToProductPay />}
        </div>
    );
};
