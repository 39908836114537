import React, { useEffect, useState, useCallback } from 'react';
import KhaltiCheckout from 'khalti-checkout-web';
import { useNavigate } from 'react-router-dom';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import AddProparty from '../addProparty/AddProparty';
import { useBookProduct } from '../hooks/useBookProduct';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const KhaltiPayment = ({ product_name, product_amount, booked_for }) => {
    const { setTransactionId, transactionId } = useContextex();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const snackMessage = 'Your transaction was successful';
    const navigate = useNavigate();
    const { performBookProduct } = useBookProduct();
    const { performBookPackage } = usePackagePurchase();

    const handlePaymentSuccess = useCallback((payload) => {
        setTransactionId(payload.transaction_id);
        setShowSnackbar(true);

        if (booked_for === 'booking' && transactionId) {
            performBookProduct();
        } else if (booked_for === 'package' && transactionId) {
            performBookPackage();
        } else if (booked_for === 'wallet' && transactionId) {
            window.location.href = '/dashboard'
        }
    }, [booked_for, performBookProduct, setTransactionId, transactionId, navigate]);

    const handlePaymentError = useCallback((error) => {
        console.error('Payment error:', error);
    }, []);

    const handleCloseWidget = useCallback(() => {
        console.log('Widget closed');
    }, []);

    useEffect(() => {
        const config = {
            publicKey: "test_public_key_d4d1c327935749508ee25b52e22ebabb",
            productIdentity: Date.now().toString(),
            productName: product_name,
            productUrl: "http://localhost:3000",
            eventHandler: {
                onSuccess: handlePaymentSuccess,
                onError: handlePaymentError,
                onClose: handleCloseWidget,
            },
            paymentPreference: ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
        };

        const checkout = new KhaltiCheckout(config);

        // Show checkout widget when component mounts
        checkout.show({ amount: product_amount * 100 });

        // Clean up on component unmount
        return () => {
            // Currently no explicit cleanup needed, but you can add cleanup code if necessary
        };

    }, [product_name, product_amount, handlePaymentSuccess, handlePaymentError, handleCloseWidget]);

    const handleCloseSnackbar = () => setShowSnackbar(false);

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
            {booked_for === 'package' && transactionId && <AddProparty />}
        </>
    );
};
