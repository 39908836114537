import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../store/action';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';

function AddGalleryCategory() {
    const { isUserId, userPropertyList, isEditSelectedProperty, editSelectedMyGallaryCategory } = useContextex();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // State hooks for form data
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState('0');
    const [galleryCategoryName, setGalleryCategoryName] = useState('');
    const [defaultSelectedProperty, setDefaultSelectedProperty] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const data = useSelector(getData);

    useEffect(() => {
        if (isEditSelectedProperty && editSelectedMyGallaryCategory) {
            const { property_id, property_title, status, cat_title, id } = editSelectedMyGallaryCategory;
            setSelectedProperty(property_id);
            setDefaultSelectedProperty(property_title || '');
            setSelectedPropertyStatus(status);
            setGalleryCategoryName(cat_title || '');
        } else {
            setSelectedProperty('');
            setSelectedPropertyStatus('0');
            setGalleryCategoryName('');
            setDefaultSelectedProperty('');
        }
    }, [isEditSelectedProperty, editSelectedMyGallaryCategory]);

    const handleAddGalleryCategory = async (event) => {
        event.preventDefault();

        const requestData = {
            status: selectedPropertyStatus,
            prop_id: selectedProperty,
            uid: isUserId,
            title: galleryCategoryName,
            record_id: isEditSelectedProperty ? editSelectedMyGallaryCategory.id : undefined
        };

        const endpoint = isEditSelectedProperty ? 'u_gal_cat_edit.php' : 'u_gal_cat_add.php';

        try {
            await dispatch(fetchData(endpoint, requestData));
            setShowSnackbar(true);
            setSelectedProperty('');
            setSelectedPropertyStatus('0');
            setGalleryCategoryName('');
            setDefaultSelectedProperty('');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleSelectProperty = (event) => {
        const { dataset, innerText } = event.target;
        setSelectedProperty(dataset.value);
        setDefaultSelectedProperty(innerText);
    };

    const handleSelectStatus = (event) => {
        setSelectedPropertyStatus(event.target.dataset.value);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };


    return (
        <>
            <form className="form-basic-information flex gap30 flex-column" onSubmit={handleAddGalleryCategory}>
                <div className="nice-select" tabIndex="0">
                    <span className="current">{selectedProperty ? defaultSelectedProperty : 'Property Type'}</span>
                    <ul className="list">
                        {userPropertyList?.map((item) => (
                            <li
                                key={item.id}
                                className={`option ${selectedProperty === item.id ? 'selected' : ''}`}
                                onClick={handleSelectProperty}
                                data-value={item.id}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>

                <fieldset className="text has-top-title">
                    <input
                        type="text"
                        placeholder="Gallery Category Name"
                        value={galleryCategoryName}
                        onChange={handleChange(setGalleryCategoryName)}
                        aria-required="true"
                        required
                    />
                    <label htmlFor="gallery-category-name">{t('Gallery Category Name')} *</label>
                </fieldset>

                <div className="nice-select" tabIndex="0">
                    <span className="current">{selectedPropertyStatus === '0' ? 'Publish' : 'Unpublish'}</span>
                    <ul className="list">
                        <li
                            className={`option ${selectedPropertyStatus === '0' ? 'selected' : ''}`}
                            onClick={handleSelectStatus}
                            data-value="0"
                        >
                            {t('Publish')}
                        </li>
                        <li
                            className={`option ${selectedPropertyStatus === '1' ? 'selected' : ''}`}
                            onClick={handleSelectStatus}
                            data-value="1"
                        >
                            {t('Unpublish')}
                        </li>
                    </ul>
                </div>

                <div className="button-submit mt-10">
                    <button type="submit" className="tf-button-primary">
                        {t('Save')} & {t('Preview')} <i className="icon-arrow-right-add"></i>
                    </button>
                </div>
            </form>
             {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={data?.ResponseMsg} />}
        </>
    );
}

export default AddGalleryCategory;
