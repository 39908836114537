import { createContext, useState } from "react";
import { getBookData, getPackData, getWalletUpdate } from "../store/selector";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../utils/CustomSnackbar";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("Payment Success");

    const bookedProduct = useSelector(getBookData);
    const addToWallet = useSelector(getWalletUpdate);
    const payPackAmnt = useSelector(getPackData);
    const dispatch = useDispatch();

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handlePaymentResponse = async (response) => {

        const { ResponseCode, ResponseMsg } = response;

        if (ResponseCode == '200') {
            await setSnackMsg(response?.ResponseMsg || 'Transaction Success');
            window.location.href = '/dashboard';
        } else {
            await setSnackMsg(ResponseMsg || 'Transaction Success..!');
        }

        await setShowSnackbar(true);
    };

    const payDirect = async() => {
        setShowSnackbar(true);
        if (bookedProduct) {
            await handlePaymentResponse(bookedProduct);
        }
    };

    const buyProperty = async() => {
        setShowSnackbar(true);
        if (bookedProduct) {
            await handlePaymentResponse(bookedProduct);
        }
    };

    const walletPayment = async() => {
        setShowSnackbar(true);
        if (addToWallet) {
            await handlePaymentResponse(addToWallet);
        }
    };

    const payForPackage = async() => {
        setShowSnackbar(true);
        if(payPackAmnt){
            await handlePaymentResponse(payPackAmnt)
        }
    }

    return (
        <>
            <PaymentContext.Provider value={{ payDirect, walletPayment, buyProperty,payForPackage }}>
                {children}
            </PaymentContext.Provider>
            {showSnackbar && (
                <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />
            )}
        </>
    );
};
