import React, { useEffect, useState } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useContextex } from '../context/useContext';  // Assuming this is your custom context
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { useBookProduct } from '../hooks/useBookProduct';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const PaypalPayment = ({ product_amount, booked_for }) => {
    const [showButtons, setShowButtons] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orderId, setOrderId] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);

    const navigate = useNavigate()
    const { performBookProduct } = useBookProduct();
    const { performBookPackage } = usePackagePurchase();

    // Assuming you have order details available from your context or props
    const { bookedOtherUserData, loginUserData, setTransactionId, transactionId, selectedPackage, productAmount, productFinalPrice } = useContextex();

    const initialOptions = {
        clientId: "Aa0Yim_XLAz89S4cqO-kT4pK3QbFsruHvEm8zDYX_Y-wIKgsGyv4TzL84dGgtWYUoJqTvKUh0JonIaKa",
        currency: "USD",
        intent: "capture",
    };

    // Function to handle creation of the PayPal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: product_amount   // Replace with dynamic value from orderDetails
                    },
                    description: 'Your order description',  // Replace with dynamic description
                },
            ],
        });
    };

    // Function to handle successful payment completion
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            // Call your API to update payment success
            setPaymentSuccess(true);
            setTransactionId(details.id)
            setShowSnackbar(true);
            if (booked_for == 'booking') {
                if (transactionId) {
                    performBookProduct();
                }
            } else if (booked_for == 'package') {
                if (transactionId) {
                    performBookPackage();
                }
            } else if (booked_for === 'wallet' && transactionId) {
                window.location.href = '/dashboard'
            }

        });
    };

    const onError = (err) => {
        setErrorMessage(err.toString());
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const snack_message = 'your transaction is Successfull'

    return (
        <div>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                    onError={(err) => onError(err)}
                />
            </PayPalScriptProvider>

            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
        </div>
    );
};

